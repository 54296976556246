<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="purchaseData === undefined"
    >
      <h4 class="alert-heading">
        {{ t('Error fetching purchase data') }}
      </h4>
      <div class="alert-body">
        {{ t('No purchase found with this purchase id. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          {{ t('Purchase List') }}
        </b-link>
        {{ t('for other purchases.') }}
      </div>
    </b-alert>

    <b-row
      v-if="purchaseData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    swm
                  </h3>
                </div>
                <!-- <p class="card-text mb-25">
                  company name, address, itn
                </p>
                <p class="card-text mb-25">
                  bank account, etc
                </p>
                <p class="card-text mb-0">
                  +998946393966
                </p> -->
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ t('Purchase') }}
                  <span class="invoice-number">#{{ purchaseData.id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Order date') }}:
                  </p>
                  <p class="invoice-date">
                    {{ changeFormat(purchaseData.order_date) }}
                  </p>
                </div>
                <!-- <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Status') }}:
                  </p>
                  <b-badge
                    till
                    :variant="`light-${resolveVariant(purchaseData.status)}`"
                    class="invoice-date"
                  >
                    {{ purchaseData.status }}
                  </b-badge>
                </div> -->
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="purchaseData.Client"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="3"
                class="p-0 pb-2"
              >
                <h6 class="mb-1">
                  {{ t('Supplier') }}:
                </h6>
                <h6 class="mb-25">
                  {{ purchaseData.Client.firstname }}
                </h6>
                <!-- <p class="card-text mb-25">
                  {{ purchaseData.Client.phone }}
                </p> -->
                <!-- <p class="card-text mb-25">
                  {{ purchaseData.Client.address }}, {{ purchaseData.Client.city }}
                </p>
                <p class="card-text mb-25">
                  {{ purchaseData.Client.phone }}
                </p>
                <p class="card-text mb-50">
                  {{ purchaseData.Client.email }}
                </p> -->
              </b-col>

              <b-col
                cols="12"
                xl="3"
                class="p-0 pb-2"
              >
                <h6 class="mb-1">
                  {{ t('Warehouse') }}:
                </h6>
                <h6 class="mb-25">
                  {{ purchaseData.Warehouse.name }}
                </h6>
                <!-- <p class="card-text mb-25">
                  {{ purchaseData.Client.phone }}
                </p> -->
                <!-- <p class="card-text mb-25">
                  {{ purchaseData.Warehouse.address }}, {{ purchaseData.Warehouse.city }}
                </p>
                <p class="card-text mb-50">
                  {{ purchaseData.Warehouse.comments }}
                </p> -->
                <!-- <p class="card-text mb-0">
                  {{ purchaseData.Client.email }}
                </p> -->
              </b-col>

              <b-col
                cols="12"
                xl="3"
                class="pb-2"
              >
                <h6 class="mb-1">
                  {{ t('Car') }}:
                </h6>
                <h6
                  v-for="car in purchaseData.orderCars"
                  :key="car.id"
                  class="p-0 mb-50"
                >
                  {{ car.number }}
                </h6>
              </b-col>

              <b-col
                cols="12"
                xl="3"
                class="p-0"
              >
                <h6 class="mb-1">
                  {{ t('Attachment') }}:
                </h6>
                <b-badge
                  v-if="purchaseData.File"
                  :href="`https://test164.public.uz/fserver/download/${purchaseData.file_id}`"
                  variant="light-primary"
                >
                  <feather-icon
                    icon="PaperclipIcon"
                    class="mr-25"
                  />
                  <span>{{ purchaseData.File.name }}</span>
                </b-badge>
                <b-badge
                  v-else
                  href="#"
                  variant="light-warning"
                >
                  <feather-icon
                    icon="PaperclipIcon"
                    class="mr-25"
                  />
                  <span>{{ t('No') }}</span>
                </b-badge>
                <!-- <h6
                  v-for="car in purchaseData.Cars"
                  :key="car.id"
                  class="mb-25"
                >
                  {{ `${car.number} ${car.name}` }}
                </h6> -->
                <!-- <p class="card-text mb-25">
                  {{ purchaseData.Client.phone }}
                </p> -->
                <!-- <p class="card-text mb-25">
                  {{ purchaseData.Warehouse.address }}, {{ purchaseData.Warehouse.city }}
                </p>
                <p class="card-text mb-25">
                  {{ purchaseData.Warehouse.comments }}
                </p> -->
                <!-- <p class="card-text mb-0">
                  {{ purchaseData.Client.email }}
                </p> -->
              </b-col>

              <!-- Col: Payment Details
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td><span class="font-weight-bold">{{ paymentDetails.totalDue }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Bank name:
                        </td>
                        <td>{{ paymentDetails.bankName }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Country:
                        </td>
                        <td>{{ paymentDetails.country }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>{{ paymentDetails.iban }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td>{{ paymentDetails.swiftCode }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col> -->
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="purchaseData.Products"
            :fields="[{ key: 'name', label: t('Product') }, {key: 'quantity', label: t('quantity') }, {key: 'weight', label: t('weight') }, {key: 'cost', label: t('cost') }, {key: 'price', label: t('Amount') }]"
          >
            <template #cell(name)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.name }}
              </b-card-text>
              <!-- <b-card-text class="text-nowrap">
                {{ data.item.description }}
              </b-card-text> -->
            </template>

            <template #cell(cost)="data">
              <b-card-text class="mb-25">
                {{ roundUp(data.item.OrdersProducts.cost) }}
              </b-card-text>
            </template>

            <template #cell(quantity)="data">
              <b-card-text class="mb-25">
                {{ roundUp(data.item.OrdersProducts.quantity) }}
              </b-card-text>
            </template>

            <template #cell(weight)="data">
              <b-card-text class="mb-25">
                {{ data.item.OrdersProducts.weight }}
              </b-card-text>
            </template>

            <template #cell(price)="data">
              <b-card-text class="mb-25">
                {{ roundUpCustom(data.item.OrdersProducts.weight*data.item.OrdersProducts.cost, 2) }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <!-- <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">Alfie Solomons</span>
                </b-card-text> -->
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ t('Subtotal') }}:
                    </p>
                    <p class="invoice-total-amount text-nowrap">
                      {{ roundUpCustom(parseFloat(totalPrice), 2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ t('Extra costs') }}:
                    </p>
                    <p class="invoice-total-amount text-nowrap">
                      {{ roundUpCustom(parseFloat(purchaseData.OrderExtras[0] ? purchaseData.OrderExtras[0].amount ? purchaseData.OrderExtras[0].amount : 0 : 0), 2) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ t('Total') }}:
                    </p>
                    <p class="invoice-total-amount text-nowrap">
                      {{ roundUpCustom(parseFloat(totalPrice) + parseFloat(purchaseData.OrderExtras[0] ? purchaseData.OrderExtras[0].amount ? purchaseData.OrderExtras[0].amount : 0 : 0), 2) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ t('Note') }}: </span>
            <span>{{ purchaseData.comments }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
          >
            Send Invoice
          </b-button> -->

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :disabled="isDeleting || submitOnProcess.save || submitOnProcess.saveandsell"
            :to="{ name: 'apps-purchase-list'}"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mx-auto"
              size="18"
            />
            {{ t('Back to list') }}
          </b-button>

          <b-button
            v-if="purchaseData.status !== 'Closed'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="isDeleting || submitOnProcess.save || submitOnProcess.saveandsell"
            @click.prevent="onSubmitSave"
          >
            <b-spinner
              v-if="submitOnProcess.save"
              small
              type="grow"
            />
            {{ t('Save') }}
          </b-button>

          <b-button
            v-if="purchaseData.status !== 'Closed'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            :disabled="isDeleting || submitOnProcess.save || submitOnProcess.saveandsell"
            @click.prevent="onSubmitSaveSell"
          >
            <b-spinner
              v-if="submitOnProcess.saveandsell"
              small
              type="grow"
            />
            {{ t('Save and sell') }}
          </b-button>

          <!-- Button: DOwnload
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printPurchase"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mx-auto"
              size="18"
            />
            {{ t('Print') }}
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :disabled="isDeleting || submitOnProcess.save || submitOnProcess.saveandsell"
            :to="{ name: 'apps-purchase-edit', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mx-auto"
              size="18"
            />
            {{ t('Edit') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            block
            :disabled="isDeleting || submitOnProcess.save || submitOnProcess.saveandsell"
            @click.prevent="promptDialog"
          >
            <feather-icon
              icon="TrashIcon"
              class="mx-auto"
              size="18"
            />
            {{ t('Delete') }}
          </b-button>

          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            block
            :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
            :to="{ name: 'apps-purchase-list'}"
          >
            {{ t('Cancel') }}
          </b-button> -->

          <!-- Button: Cancel -->
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row
      v-else-if="purchaseData !== undefined"
      class="invoice-preview"
    >

      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <div
            class="text-center text-primary my-2"
          >
            <b-spinner
              class="align-middle mr-1"
            />
            <strong>{{ t('Загрузка') }}...</strong>
          </div>
        </b-card>
      </b-col>
    </b-row> -->
    <div
      v-else-if="purchaseData !== undefined"
      class="text-center text-primary my-2"
    >
      <b-spinner
        class="align-middle mr-1"
      />
      <strong>{{ t('Загрузка') }}...</strong>
    </div>
  </section>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BSpinner, BBadge,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import purchaseStoreModule from '../purchaseStoreModule'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BSpinner,
    BBadge,

    Logo,
  },
  methods: {
    promptDialog() {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            this.deletePurchase()
          }
        })
    },
  },
  setup() {
    const toast = useToast()
    const { t } = useI18nUtils()
    const isDeleting = ref(false)

    const purchaseData = ref(null)

    // Invoice Description
    // ? Your real data will contain this information
    const purchaseProducts = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ]

    const PURCHASE_APP_STORE_MODULE_NAME = 'app-purchase'

    // Register module
    if (!store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.registerModule(PURCHASE_APP_STORE_MODULE_NAME, purchaseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.unregisterModule(PURCHASE_APP_STORE_MODULE_NAME)
    })

    const fetchPurchase = () => {
      store.dispatch('app-purchase/fetchPurchase', { id: router.currentRoute.params.id })
        .then(response => {
          if (response.data === null) {
            purchaseData.value = undefined
          } else {
            purchaseData.value = response.data
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            purchaseData.value = undefined
          }
        })
    }
    fetchPurchase()

    const deletePurchase = () => {
      isDeleting.value = true
      store.dispatch('app-purchase/deletePurchase', { id: purchaseData.value.id })
        .then(response => {
          if (response.data.code === 1) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Успешно',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Вы успешно нажали на кнопку сохранить.',
              },
            })
            // resetForm()
            router.push({ name: 'apps-purchase-list' })
          } else {
            // purchaseData.value = response.data
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            // purchaseData.value = undefined
          }
        })
        .finally(() => {
          isDeleting.value = false
        })
    }

    const totalPrice = computed(() => purchaseData.value.Products.reduce((total, item) => total + item.OrdersProducts.weight * item.OrdersProducts.cost, 0))

    const resolveVariant = status => {
      if (status === 'New') return 'primary'
      // if (status === 'Closed') return 'danger'
      // if (status === 'Downloaded') return 'secondary'
      if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 'Closed') return 'success'
      return 'primary'
    }

    const printPurchase = () => {
      window.print()
    }

    const submitOnProcess = ref({
      save: false,
      saveandsell: false,
      file: false,
    })

    const onSubmitSave = () => {
      submitOnProcess.value.save = true
      store.dispatch('app-purchase/updatePurchase', {
        id: purchaseData.value.id,
        date: purchaseData.value.order_date.slice(0, 10),
        supplier: purchaseData.value.client_id,
        warehouse: purchaseData.value.wm_id,
        car: purchaseData.value.orderCars[0].id,
        items: purchaseData.value.Products.map(product => ({
          product: product.id,
          itemTitle: product.name,
          cost: parseFloat(product.OrdersProducts.cost),
          quantity: parseFloat(product.OrdersProducts.quantity),
          weight: parseFloat(product.OrdersProducts.weight),
          subItems: product.WmSubitems.map(sub => ({ weight: parseFloat(sub.weight) })),
          description: product.description,
        })),
        status: 'Closed',
        note: purchaseData.value.comments,
        actiontype: 'save',
      })
        .then(response => {
          console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          fetchPurchase()
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.save = false
        })
    }

    const onSubmitSaveSell = () => {
      submitOnProcess.value.saveandsell = true

      store.dispatch('app-purchase/updatePurchase', {
        id: purchaseData.value.id,
        date: purchaseData.value.order_date.slice(0, 10),
        supplier: purchaseData.value.client_id,
        warehouse: purchaseData.value.wm_id,
        car: purchaseData.value.orderCars[0].id,
        status: 'Closed',
        note: purchaseData.value.comments,
        actiontype: 'saveandsell',
        items: purchaseData.value.Products.map(product => ({
          product: product.id,
          itemTitle: product.name,
          cost: parseFloat(product.OrdersProducts.cost),
          quantity: parseFloat(product.OrdersProducts.quantity),
          weight: parseFloat(product.OrdersProducts.weight),
          subItems: product.WmSubitems.map(sub => ({ weight: sub.weight })),
          description: product.description,
        })),
      })
        .then(response => {
          // console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          router.push({ name: 'apps-sales-add', params: { tablename: 'purchase', id: response.data.order_id } })
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value.saveandsell = false
        })
    }
    const changeFormat = date => moment(date).format('YYYY-MM-DD')
    const roundUp = num => {
      const x = num ? (Math.round(num * 10) / 10) : 0
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    }

    function roundUpCustom(givnum, decimals = 2) {
      if (givnum !== null) {
        const num = Number.isNaN(givnum) ? 0 : givnum
        const tem = 10 ** decimals
        const preval = (
          Math.round(
            num * tem + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / (100 ** decimals)))
          ) / tem
        ).toFixed(decimals)
        const parts = preval.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return parts.join('.')
      }
      return 0
    }

    return {
      roundUp,
      roundUpCustom,
      changeFormat,
      purchaseData,
      purchaseProducts,
      printPurchase,
      t,
      totalPrice,
      resolveVariant,
      deletePurchase,
      isDeleting,
      submitOnProcess,
      onSubmitSave,
      onSubmitSaveSell,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
